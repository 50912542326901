import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import type { Context } from '@nuxt/types'

export type DevicePlatform = 'android' | 'ios'
type DeviceRegistration = {
  id: number,
  name: string | null,
  registration_id: string,
  device_id: string | null,
  active: boolean,
  date_created: string | Date,
  type: string
}
interface IPushNotificationsService {
  $axios: NuxtAxiosInstance
  registerDevice: (registerToken: string, devicePlatform: DevicePlatform) => Promise<DeviceRegistration>,
  testPushNotification: () => Promise<any>
}

export default class PushNotificationsService implements IPushNotificationsService {
  $axios: NuxtAxiosInstance
  constructor (context: Context) {
    this.$axios = context.$axios
  }

  registerDevice (registerToken:string, devicePlatform: DevicePlatform) {
    return this.$axios.$post('/api/user/devices', {
      registration_id: registerToken,
      type: devicePlatform
    })
  }

  testPushNotification () {
    return this.$axios.$post('/api/user/send_user_test_notification')
  }
}
